import React from "react"
import Layout from "../components/layout"

export default function Locations() {
  return (
    <Layout>
      <h1>You can reach me at the below location! (*Mock location*)</h1>
      
      <div class="mapouter">
        <div class="gmap_canvas">
          <iframe width="800" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=waterway%20point%20singapore&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
          <a href="https://soap2day-to.com">soap2day</a><br/>
          <a href="https://www.embedgooglemap.net">how to make a website map</a>
          </div>
      </div>

    </Layout>
  );
}